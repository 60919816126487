/* eslint-disable react-hooks/exhaustive-deps */
import { PublicAppointmentClass, PrivateAppointmentClass } from 'apps/appointments/models/appointment';
import http, {httpMap} from './http';
import { ActivityClass } from 'apps/activities/models/activity';
import { BlacklistedDaysClass } from 'apps/calendar/models/blacklisted_days';
import { HelpCategoryClass } from 'apps/help_categories/models/help_category';
import { HelpServiceClass } from 'apps/help_services/models/help_service';
import { ReportClass } from 'apps/reports/models/report';


class ApiService {

    //Get Public Appointment List By Office
    async getPublicAppointmentsByOffice(office) {        
        let result = await http.get('public_appointments_list/' + office);       
        return result.data.map((data) => new PublicAppointmentClass(data));
    }

    //Get Unavailable Dates List By Office
    async getUnavailableDatesByOffice(office) {        
        let result = await http.get('unavailable_appointments_list/' + office + '/unavailable');       
        return result.data.map((data) => new PublicAppointmentClass(data));
    }

    // Create Appointment
    async createAppointment(formData) {
        let result = await http.post('appointments/', {...formData });
        return result;
    }

    // Delete Appointment
    async deleteAppointment(id) {
        let result = await http.delete('appointments/' + id + '/');
        return result;
    }

    // Get Appointments List
    async getAppointmentsList() {        
        let result = await http.get('appointments/');       
        return result.data.map((data) => new PrivateAppointmentClass(data));
    }

    // Get Appointment
    async getAppointment(id) {        
        let result = await http.get('appointments/' + id);       
        return result.data;
    }

    // Get Appointments List
    async getActivities() {        
        let result = await http.get('activities/');       
        return result.data.map((data) => new ActivityClass(data));
    }

    // Create Blacklisted Days
    async createdBlacklistedDays(formData) {
        let result = await http.post('blacklisted_days/', {...formData });
        return result;
    }

    // Delete Appointment
    async deleteBlacklistedDays(id) {
        let result = await http.delete('blacklisted_days/' + id + '/');
        return result;
    }

    // Get Blacklisted days by office
    async getBlacklistedDaysByOffice(office) {        
        let result = await http.get('blacklisted_days_by_office/' + office);       
        return result.data.map((data) => new BlacklistedDaysClass(data));
    }

    //Get Help Category List
    async getHelpCategoryList() {
        let result = await http.get('help_categories/');
        return result.data.map((data) => new HelpCategoryClass(data)); 
    }

    //Get Help Category
    async getHelpCategory(slug) {
        let result = await http.get('help_categories/' + slug);
        return result.data; 
    }

    //Get Help Service List
    async getHelpServiceList() {
        let result = await http.get('help_services/');
        return result.data.map((data) => new HelpServiceClass(data)); 
    }

    //Get Help Service List
    async getHelpService(slug) {
        let result = await http.get('help_services/' + slug);
        return result.data; 
    }

     //Get Help Services By Help Category
     async getHelpServicesByHelpCategory(help_category) {        
        let result = await http.get('help_services/help_services_by_help_category/' + help_category);       
        return result.data.map((data) => new HelpServiceClass(data));
    }

    //Send Assistance Request
    async sendAssistanceRequest(formData) {
        let result = await http.post('send_email/', {...formData });
        return result;
    }

    //Get Captcha Image
    async getCaptcha() {
        let result = await http.get('captcha-generator/');
        return result.data;
    }

    //Create Help Category
    async createHelpCategory(formData) {
        let result = await http.post('help_categories/', {...formData });
        return result;
    }

    //Edit Help Category
    async editHelpCategory(slug, formData) {
        let result = await http.patch('help_categories/' + slug + '/', {...formData });
        return result;
    }

    //Delete Help Category
    async deleteHelpCategory(slug) {
        let result = await http.delete('help_categories/' + slug + '/');
        return result;
    }

    //Create Help Service
    async createHelpService(formData) {
        let result = await http.post('help_services/', {...formData });
        return result;
    }

    //Edit Help Service
    async editHelpService(slug, formData) {
        let result = await http.patch('help_services/' + slug + '/', {...formData });
        return result;
    }

    //Delete Help Service
    async deleteHelpService(slug) {
        let result = await http.delete('help_services/' + slug + '/');
        return result;
    }

    // Get Address
    async getAddress(latitude, longitude) {
        let result = await httpMap.get(
            `reverse?format=json&lat=${latitude}&lon=${longitude}&zoom=18&addressdetails=1`,            
        );
        return result;
    }

    //Send Report
    async sendReport(formData) {
        let result = await http.post('reports/', formData);
        return result;
    }

    // Get Appointments List
    async getReportsList() {        
        let result = await http.get('reports/');       
        return result.data.map((data) => new ReportClass(data));
    }

    // Get Report
    async getReport(id) {        
        let result = await http.get('reports/' + id);       
        return result.data;
    }

    async editReport(id, formData) {
        let result = await http.patch('reports/' + id + '/', {...formData });
        return result;
    }

    // Delete Report
    async deleteReport(id) {
        let result = await http.delete('reports/' + id + '/');
        return result;
    }

    async getLastReports() {
        let result = await http.get('reports/latest/');       
        return result.data.map((data) => new ReportClass(data));
    }
}

export default new ApiService();
