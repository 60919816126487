import { OfficeClass } from "pa_kit/apps/offices/models/office";

export const appointmentStepper = [
    {
        name: "Ufficio",
        slug: "ufficio",
        order: 1
    },
    {
        name: "Data e orario",
        slug: "date_and_time",
        order: 2
    },
    {
        name: "Dettagli appuntamento",
        slug: "details",
        order: 3
    },
    {
        name: "Richiedente",
        slug: "user_data",
        order: 4
    },
    {
        name: "Riepilogo",
        slug: "recap",
        order: 5
    }
];


export class PrivateAppointmentClass {    
  
    constructor(data){
        this.id = data.id;
        this.first_name = data.first_name;
        this.last_name = data.last_name;
        this.email = data.email;
        this.fiscal_code = data.fiscal_code;
        this.phone = data.phone;
        this.reason = data.reason;
        this.details = data.details;
        this.appointment_date = data.appointment_date;
        this.startDate = data.startDate;
        this.endDate = data.endDate;
        this.office_id = data.office;
        this.office = data.office_data ? new OfficeClass(data.office_data) : null;
        this.state = data.state;
        this.created_date = data.created_date;
    }
}

export class PublicAppointmentClass {    
  
    constructor(data){
        this.id = data.id;
        this.appointment_date = data.appointment_date;
        this.office = data.office_data ? new OfficeClass(data.office_data) : null;
        this.state = data.state;
    }
}

export const formatDate = (date) => {
    const formattedTime = new Date(date).toLocaleDateString('it-IT', {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    });

    return formattedTime;
}

export const convertToGoogleCalendarFormat = (inputDate) => {
    
    const date = new Date(inputDate);
  
    // Get the date and time components adjusted for the timezone
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');
  
    // Create the Google Calendar format string
    const googleCalendarFormat = `${year}${month}${day}T${hours}${minutes}${seconds}Z`;
  
    return googleCalendarFormat;
}

export const convertToOutlookCalendarFormat = (inputDate) => {
    
    const date = new Date(inputDate);
  
    // Get the date and time components in UTC
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');
  
    // Create the Outlook Calendar format string with the timezone offset (+00:00)
    const outlookCalendarFormat = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}+00:00`;
  
    return outlookCalendarFormat;
}