
export class HelpCategoryClass {    
  
    constructor(data){
        this.id = data.id;
        this.name = data.name;
        this.slug = data.slug;
        this.referent = data.referent;
        this.is_help = data.is_help;
        this.is_report = data.is_report;
    }
    
}