import { OfficeClass } from "pa_kit/apps/offices/models/office";

export class BlacklistedDaysClass {    
  
    constructor(data){
        this.id = data.id;
        this.from_day = data.from_day;
        this.to_day = data.to_day;
        this.office = data.office_data ? new OfficeClass(data.office_data) : null;
    }
}