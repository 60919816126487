/* eslint-disable array-callback-return */
// @ts-nocheck
import React, { useContext, Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { ActaAuthContext } from "./pa_kit/auth";
import App from './app';

const AppRoutes = () => {

    const auth = useContext(ActaAuthContext);

    const theme = auth.theme;  

    const defaultTheme = {
        "--color-primary": theme.primary_color,
        "--color-secondary": theme.secondary_color,
        "--color-tertiary": theme.tertiary_color,
        "--color-quaternary": theme.quaternary_color,
    }    

    const applyTheme = (theme) => {
        Object.keys(theme).map((key) => {
            const value = theme[key];
            document.documentElement.style.setProperty(key, value);
        });
    };
    
    applyTheme(defaultTheme);

    const user = auth.user;

    const PageAppointments = lazy(() => import('apps/pages/components/page_appointments')); 
    const PageAssistance = lazy(() => import('apps/pages/components/page_assistance')); 
    const PageReport = lazy(() => import('apps/pages/components/page_report')); 

    const Dashboard = lazy(() => import('admin/dashboard/dashboard'));
    const Main = lazy(() => import('admin/dashboard/main'));
    const AppointmentPage = lazy(() => import('admin/appointments/appointment_page'));
    const AppointmentsList = lazy(() => import('admin/appointments/appointments_list'));
    const OfficesList = lazy(() => import('admin/offices/offices_list'));
    const OfficePage = lazy(() => import('admin/offices/office_page'));
    const ManageCalendar = lazy(() => import('admin/offices/manage_calendar'));
    const ActivitiesList = lazy(() => import('admin/activities/activities_list'));
    const HelpCategoriesList = lazy(() => import('admin/help_categories/help_categories_list'));
    const CreateHelpCategory = lazy(() => import('admin/help_categories/create_help_category'));
    const EditHelpCategory = lazy(() => import('admin/help_categories/edit_help_category'));
    const HelpServicesList = lazy(() => import('admin/help_services/help_services_list'));
    const CreateHelpService = lazy(() => import('admin/help_services/create_help_service'));
    const EditHelpService = lazy(() => import('admin/help_services/edit_help_service'));
    const ReportsList = lazy(() => import('admin/reports/reports_list'));
    const ReportPage = lazy(() => import('admin/reports/report_page'));


    if(auth.isLoading) return <div></div>;
    return <>
        <BrowserRouter>
            <Suspense fallback={<div></div>}>
                <Routes>
                    <Route path="/" element={<App />} >
                        <Route path="" element={<PageAppointments />} ></Route>                                                           
                        <Route path="richiesta-assistenza/" element={<PageAssistance />} ></Route>                                                           
                        <Route path="segnalazione-disservizio/" element={<PageReport />} ></Route>                                                           
                    </Route>

                    <Route path="/admin" element={user && user.hasPermissions() && !user.isEditor2() ? <Dashboard /> : <Navigate to="/" />} >
                        <Route path="" element={<Main />}></Route>
                        <Route path="appointments" element={<AppointmentsList />}></Route>
                        <Route path="appointments/:appointmentID" element={<AppointmentPage />}></Route>
                        <Route path="offices/" element={<OfficesList />}></Route>
                        <Route path="offices/:officeID" element={<OfficePage />}></Route>
                        <Route path="manage-calendar/:officeID" element={<ManageCalendar />}></Route>
                        <Route path="activities/" element={<ActivitiesList />}></Route>
                        <Route path="help-categories/" element={<HelpCategoriesList />}></Route>
                        <Route path="help-services/" element={<HelpServicesList />}></Route>
                        <Route path="create-help-category" element={<CreateHelpCategory />}></Route>
                        <Route path="edit-help-category/:categorySlug" element={<EditHelpCategory />}></Route>
                        <Route path="create-help-service" element={<CreateHelpService />}></Route>
                        <Route path="edit-help-service/:serviceSlug" element={<EditHelpService />}></Route>
                        <Route path="reports/" element={<ReportsList />}></Route>
                        <Route path="reports/:reportID" element={<ReportPage />}></Route>
                    </Route>
                </Routes>
            </Suspense>
        </BrowserRouter>
    </>;
}

export default AppRoutes;
