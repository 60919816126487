import axios from 'axios';
import env from '../env';
import { getCookie } from 'pa_kit/services/manage_cookies';

const baseURL = env.BACKEND_BASE_URL + 'api/';

const http = axios.create({
    baseURL: baseURL,
    headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': getCookie('csrftoken')
    }, 
    withCredentials: true
});

export const httpMap = axios.create({
    baseURL: 'https://nominatim.openstreetmap.org/',
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: false
})


export default http;
