import { HelpCategoryClass } from "apps/help_categories/models/help_category";

export const reportStepper = [
    {
        name: "Autorizzazioni e condizioni",
        slug: "privacy_policy",
        order: 1
    },
    {
        name: "Dati Segnalazione",
        slug: "report_info",
        order: 2
    },
    {
        name: "Riepilogo",
        slug: "recap",
        order: 5
    }
];

export class ReportClass {    
  
    constructor(data){
        this.id = data.id;
        this.category = data.category ? new HelpCategoryClass(data.category_data) : null;
        this.category_id = data.category;
        this.title = data.title;
        this.description = data.description;
        this.address = data.address;
        this.reporter_first_name = data.reporter_first_name;
        this.reporter_last_name = data.reporter_last_name;
        this.reporter_email = data.reporter_email;
        this.reporter_fiscal_code = data.reporter_fiscal_code;
        this.reporter_phone = data.reporter_phone;
        this.report_image_1 = data.report_image_1;
        this.report_image_2 = data.report_image_2;
        this.report_image_3 = data.report_image_3;
        this.report_document_1 = data.report_document_1;
        this.report_document_2 = data.report_document_2;
        this.report_document_3 = data.report_document_3;
        this.created_date = data.created_date;
        this.state = data.state;
        
    }
}