import { HelpCategoryClass } from "apps/help_categories/models/help_category";

export class HelpServiceClass {    
  
    constructor(data){
        this.id = data.id;
        this.name = data.name;
        this.slug = data.slug;
        this.referent = data.referent;
        this.help_category = data.help_category_data ? new HelpCategoryClass(data.help_category_data) : null;
    }
    
}